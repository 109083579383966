<template>
  <campaign-layout
    :showBack="computed_debug"
    :showMenu="computed_debug"
    :pageTitle="$t('pageTitle.Mission')"
  >
    <div class="campaign-content">
      <div class="image-container">
        <img
          v-if="campaignData && campaignData.url_mission_cover"
          :src="campaignData.url_mission_cover"
          @error="handleImgError"
          alt="Mission Cover"
          class="mission-cover"
        />
      </div>

      <div
        v-if="campaignData && campaignData.mission_description"
        class="mission-description"
      >
        <div
          v-html="
            campaignData.mission_description['it-IT'] ||
            campaignData.mission_description['en-EN']
          "
          class="mission-text"
        ></div>
      </div>

      <div id="custom-avatar-selection" class="custom-avatar-section">
        <h4 class="font-bold">
          Seleziona un avatar personalizzato per la campagna
        </h4>
        <avatars-slider
          @changeCurrentSlide="getCurrentAvatar"
          @clickedCurrentSlide="selectCurrentAvatar"
          :awardsList="customAvatarsList"
        ></avatars-slider>
        <ion-card class="avatar-details" v-if="currentAvatar">
          <p>{{ $root.translate(currentAvatar.title) }}</p>
          <p>{{ $root.translate(currentAvatar.nomeCampagna) }}</p>
        </ion-card>
        <h4>Avatar selezionati</h4>
        <div class="selected-avatars">
          <div v-if="selectedAvatars.length <= 0">
            Non hai selezionato nessun Avatar
          </div>
          <div
            v-else
            class="selected-avatars-icons"
            v-for="avatar in selectedAvatars"
            :key="avatar.id"
          >
            <img
              @click="removeAvatar(avatar.id)"
              :src="
                avatar.url_image || 'https://placehold.co/300x300?text=Avatar'
              "
              @error="handleImgError"
              alt="Avatar"
              class="avatar-icon"
            />
          </div>
        </div>
      </div>

      <div id="custom-badge-selector" class="custom-badge-section">
        <h4 class="font-bold">Seleziona i tuoi 3 Badge prioritari!</h4>
        <avatars-slider
          @changeCurrentSlide="getCurrentBadge"
          @clickedCurrentSlide="selectCurrentBadge"
          :awardsList="badgesList"
        ></avatars-slider>
        <ion-card class="badge-details" v-if="currentBadge">
          <p>
            <strong>{{ $root.translate(currentBadge.title) }}</strong>
          </p>
          <p>Raddoppio punti: {{ currentBadge.raddoppio_punti }}</p>
          <p>
            Perc. Completamento:
            {{ currentBadge.perc_completamento.toFixed(2) }}
          </p>
        </ion-card>
        <h4>Badge selezionati</h4>
        <div class="selected-badges">
          <div v-if="selectedBadges.length <= 0">
            Non hai selezionato nessun Badge
          </div>
          <div
            v-else
            class="selected-badges-icons"
            v-for="badge in selectedBadges"
            :key="badge.id"
          >
            <img
              @click="removeBadge(badge.id)"
              :src="badge.url_image"
              @error="handleImgError"
              alt="Badge"
              class="badge-icon"
            />
          </div>
        </div>
      </div>

      <div class="action-button-container">
        <button @click="close" class="action-button">
          {{ $t("mission.close") }}
        </button>
      </div>
    </div>
  </campaign-layout>
</template>

<script>
import { IonButton, IonCard } from "@ionic/vue";
import ApiService from "../../common/service.api";
import CampaignLayout from "../../components/CampaignLayout";
import AvatarsSlider from "../../components/AvatarsSlider.vue";
import CampaignService from "../../common/mixins/Campaign";
import { useRoute } from "vue-router";

export default {
  name: "Mission",
  components: {
    CampaignLayout,
    AvatarsSlider,
    IonButton,
    IonCard,
  },
  mixins: [CampaignService],
  data() {
    return {
      campaignData: {},
      selectedBadges: [],
      selectedAvatars: [],
      currentBadgeIndex: 0,
      currentAvatarIndex: 0,
      badgesList: [],
      customAvatarsList: [
        {
          id: 0,
          title: { "it-IT": "Avatar numero 1", "en-EN": "Avatar number 1" },
          nomeCampagna: { "it-IT": "Orientato alle vendite", "en-EN": "" },
          url_image: "https://placehold.co/300x300?text=Avatar1",
        },
        {
          id: 1,
          title: { "it-IT": "Avatar numero 2", "en-EN": "Avatar number 2" },
          nomeCampagna: {
            "it-IT": "Orientato al funneling",
            "en-EN": "lorem ipsum",
          },
          url_image: "https://placehold.co/300x300?text=Avatar2",
        },
        {
          id: 2,
          title: { "it-IT": "Avatar numero 3", "en-EN": "Avatar number 3" },
          nomeCampagna: {
            "it-IT": "Orientato alla velocità",
            "en-EN": "lorem ipsum",
          },
          url_image: "https://placehold.co/300x300?text=Avatar3",
        },
        {
          id: 3,
          title: { "it-IT": "Avatar numero 4", "en-EN": "Avatar number 4" },
          nomeCampagna: {
            "it-IT": "Orientato al marketing",
            "en-EN": "lorem ipsum",
          },
          url_image: "https://placehold.co/300x300?text=Avatar4",
        },
        {
          id: 4,
          title: { "it-IT": "Avatar numero 5", "en-EN": "Avatar number 5" },
          nomeCampagna: {
            "it-IT": "Orientato alla vendita aggressiva",
            "en-EN": "lorem ipsum",
          },
          url_image: "https://placehold.co/300x300?text=Avatar5",
        },
        {
          id: 5,
          title: { "it-IT": "Avatar numero 6", "en-EN": "Avatar number 6" },
          nomeCampagna: {
            "it-IT": "Orientato alle relazioni pubbliche",
            "en-EN": "lorem ipsum",
          },
          url_image: "https://placehold.co/300x300?text=Avatar6",
        },
        {
          id: 6,
          title: { "it-IT": "Avatar numero 7", "en-EN": "Avatar number 7" },
          nomeCampagna: {
            "it-IT": "Orientato all'innovazione",
            "en-EN": "lorem ipsum",
          },
          url_image: "https://placehold.co/300x300?text=Avatar7",
        },
      ],
    };
  },
  computed: {
    currentAvatar() {
      return this.customAvatarsList[this.currentAvatarIndex] || {};
    },
    currentBadge() {
      return this.badgesList[this.currentBadgeIndex];
    },
    computed_debug() {
      return this.$cookies.get("debug") !== 1;
    },
  },
  methods: {
    handleImgError(event) {
      event.target.src = "https://placehold.co/300x300?text=MartechRevolution";
    },
    getCurrentAvatar(index) {
      this.currentAvatarIndex = index;
    },
    getCurrentBadge(index) {
      this.currentBadgeIndex = index;
    },
    selectCurrentAvatar(id) {
      id = id || this.currentAvatar.id;

      if (this.selectedAvatars.length < 1) {
        this.selectedAvatars.push(this.currentAvatar);
      }
    },
    selectCurrentBadge(id) {
      id = id || this.currentBadge.id;

      if (this.selectedBadges.length < 3) {
        this.selectedBadges.push(this.currentBadge);
      }
    },
    removeAvatar(id) {
      const index = this.selectedAvatars.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.selectedAvatars.splice(index, 1);
      }
    },
    removeBadge(id) {
      const index = this.selectedBadges.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.selectedBadges.splice(index, 1);
      }
    },
    close() {
      this.$router.push(this.getCampaignDefaultUrl(this.campaignData, true));
    },
  },
  mounted() {
    const { id } = this.$route.params;
    this.campaignId = id;
    this.getCampaign(id).then((data) => {
      this.campaignData = data;
      ApiService.get(`CampaignManager/GetUserBadges/${id}`).then((res) => {
        this.badgesList = res.data;
      });
    });
  },
};
</script>

<style scoped>
.campaign-content {
  padding: 16px;
}

.image-container {
  text-align: center;
  margin-bottom: 16px;
}

.mission-cover {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mission-description {
  margin-bottom: 24px;
}

.mission-text {
  white-space: pre-wrap;
  text-align: justify;
}

.custom-avatar-section,
.custom-badge-section {
  margin-bottom: 32px;
  border: 2px solid var(--ion-color-primary);
  padding: 10px 0;
  border-radius: var(--ion-border-radius);
}

.custom-avatar-section h4,
.custom-badge-section h4 {
  text-align: center;
  margin-bottom: 16px;
}

.avatar-details,
.badge-details {
  margin-top: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
}

.selected-avatars,
.selected-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.selected-avatars-icons,
.selected-badges-icons {
  margin: 4px;
}

.avatar-icon,
.badge-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
}

.avatar-icon:hover,
.badge-icon:hover {
  transform: scale(1.1);
}

.action-button-container {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.action-button {
  padding: 12px 24px;
  background: var(--ion-color-primary);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.action-button:hover {
  background: var(--ion-color-primary);
}
</style>
